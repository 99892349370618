<header class="main_header header_container" id="myHeader" [ngClass]="{
  'scrolling_top': footerService.isScrollingTopUpdated && isScrollingTop,
  'scrolling_bottom': footerService.isScrollingTopUpdated && !isScrollingTop,
  'scrolled': isScrolled,
  'isInnerPage': isInnerPage,
  'spaces_open': spacesDropdown.isOpen()
}">
  <div class="mx-1660">
    <div class="inner_header desktop_header">
      <div class="logo_wrapper">
        <a routerLink="/{{footerService.PropertyId()}}" class="main_logo" (click)="navClickTracker('logo', 'global_nav')">
          <svg width="134" height="35" viewBox="0 0 134 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1532_1567)">
              <path d="M39.6395 8.33938C41.1003 8.33938 42.1257 7.34676 42.1257 5.74992C42.1257 4.38326 41.1003 3.39062 39.8081 3.39062C38.4736 3.39062 37.4061 4.38326 37.4061 5.83623C37.4202 7.2029 38.3191 8.33938 39.6395 8.33938ZM38.7826 28.7242C38.7826 30.58 38.7405 31.2706 37.2797 31.6734C36.8021 31.8172 36.465 32.0331 36.465 32.6228C36.465 33.5292 37.2375 33.6154 37.9258 33.6154H42.7718C43.5444 33.6154 44.1905 33.5292 44.1905 32.6228C44.1905 31.9899 43.755 31.8028 43.0808 31.5295C42.0554 31.1267 42.0133 30.6232 42.0133 28.0337V17.5032C42.0133 11.8208 41.3671 11.8208 40.721 11.8208C40.2013 11.8208 39.2181 12.3674 38.7546 12.5976C36.2262 13.8205 36.0576 14.1369 36.0576 14.6836C36.0576 15.2303 36.5774 15.4028 37.2094 15.5467C38.7967 15.8632 38.7967 17.1868 38.7967 19.0857V28.7242H38.7826Z" fill="#006878"/>
              <path d="M104.969 25.2288C104.969 28.9979 102.735 31.7744 98.493 31.7744C95.9642 31.7744 94.2931 29.9186 94.2931 27.6025C94.2931 22.8838 99.434 22.035 102.861 22.035H104.954V25.2288H104.969ZM109.603 31.6737C108.536 31.3572 108.185 30.8105 108.185 28.034V21.2007V20.7116C108.185 14.986 104.631 11.8066 98.8018 11.8066C97.2148 11.8066 95.3748 12.1663 93.745 12.9863C93.0991 13.3459 92.0319 14.0796 92.0319 14.9428C92.0319 15.7196 92.5938 16.0793 93.2252 16.0793C94.855 16.0793 95.0232 13.9934 98.9708 13.9934C102.019 13.9934 104.716 16.0793 104.969 19.5319V19.9635H102.23C93.745 19.9635 90.8799 24.4375 90.8799 27.8038C90.8799 32.1628 94.4338 34.2919 98.1696 34.2919C101.26 34.2919 103.704 32.7526 105.333 30.0192V31.1557C105.333 32.7958 105.333 33.6014 106.921 33.6014H108.803C109.491 33.6014 110.517 33.6445 110.517 32.6087C110.502 31.9901 110.067 31.8175 109.603 31.6737Z" fill="#006878"/>
              <path d="M78.7865 32.2554C74.0244 32.2554 71.6786 28.0835 71.6786 23.4512C71.6786 18.7758 73.912 14.2298 78.8843 14.2298C83.6461 14.2298 86.1748 18.3586 86.1748 23.034C86.1603 28.9754 82.4245 32.2554 78.7865 32.2554ZM78.9547 12C73.1676 12 68.2373 17.1357 68.2373 23.2642C68.2373 29.4358 72.9147 34.4852 78.7299 34.4852C84.8118 34.4852 89.5736 29.5796 89.5736 23.0916C89.5881 16.9056 84.7415 12 78.9547 12Z" fill="#006878"/>
              <path d="M48.2069 28.0479C48.2069 30.8244 47.954 31.184 47.0972 31.5437C46.2825 31.8602 45.9454 32.0472 45.9454 32.5938C45.9454 33.644 46.8444 33.644 47.5748 33.644H52.5893C53.1933 33.644 53.699 33.2844 53.699 32.5938C53.699 32.0041 53.2636 31.817 52.4068 31.5005C51.4235 31.1409 51.4235 30.4504 51.4235 28.0479V20.5528C51.4235 18.7834 51.5078 17.5174 52.8001 16.237C53.994 15.1005 55.7076 14.3237 57.5196 14.3237C60.7362 14.3237 62.4921 16.2802 62.4921 19.7328V28.0479C62.4921 30.364 62.3656 31.0833 61.6352 31.4574L61.1998 31.6876C60.8065 31.9177 60.5115 32.0904 60.5115 32.5938C60.5115 33.644 61.3684 33.644 62.0988 33.644H66.7621C67.324 33.644 67.8718 33.2844 67.8718 32.5938C67.8718 32.0041 67.4925 31.817 66.4531 31.4142C65.8491 31.184 65.6806 30.5511 65.6806 28.0479V20.596C65.6806 18.2367 65.4699 16.1076 63.9248 14.2806C62.5061 12.6406 60.4132 11.835 58.3905 11.835C55.0896 11.835 52.8984 13.101 50.8897 15.7911C50.5947 13.2016 50.1593 11.835 49.0918 11.835C48.4035 11.835 47.4203 12.7844 46.9006 13.2016C46.4651 13.5612 45.833 14.0216 45.833 14.6977C45.833 15.2013 46.437 15.3739 47.2939 15.6904C48.0664 15.9637 48.1928 16.7406 48.1928 18.8696V28.0479H48.2069Z" fill="#006878"/>
              <path d="M33.5006 31.4574L32.6298 31.1985C32.096 31.0402 31.7308 30.5368 31.7167 29.9613L31.1127 4.09536C31.0987 3.49115 31.478 2.94449 32.0399 2.78624L32.9669 2.52729C33.4866 2.39782 34.1187 2.21081 34.1187 1.53466C34.1187 0.484491 33.6551 0.398176 32.9248 0.398176H27.3904C26.6601 0.398176 26.3651 0.858524 25.8032 2.31151C23.2328 9.13045 20.2268 16.1651 17.0102 23.1999C14.0183 16.3666 11.4478 9.56203 8.83514 2.78624C7.96429 0.527648 7.79573 0.383789 6.68608 0.383789H1.37654C0.730412 0.383789 0.210696 0.657122 0.210696 1.37642C0.210696 2.0094 0.66018 2.15326 1.19394 2.26835L1.98054 2.42659C2.58453 2.54168 3.01998 3.10273 3.00593 3.76449L2.37384 30.0764C2.35979 30.6086 2.05078 31.0834 1.5732 31.2704L1.01134 31.5006C0.477578 31.7163 0 31.9034 0 32.6371C0 33.6297 0.772555 33.6297 1.5451 33.6297H6.68608C7.24794 33.6297 7.83785 33.3995 7.83785 32.7234C7.83785 32.0185 7.34627 31.7595 6.50348 31.4718L6.2085 31.3711L5.70283 31.1985C5.22526 31.0402 4.90219 30.5655 4.91623 30.0332L5.53428 4.29677C8.4559 12.5111 11.6725 20.3083 15.4089 28.4795C15.8443 29.4577 17.0102 29.6448 17.558 28.4795C20.8027 21.3873 24.525 12.7413 27.4466 5.01606L28.0507 30.177C28.0647 30.6806 27.7697 31.1409 27.3342 31.3279L26.7864 31.5437C26.1825 31.7883 25.9015 31.9897 25.9015 32.5795C25.9015 33.529 26.7163 33.6297 27.4045 33.6297H33.1916C33.9221 33.6297 34.7367 33.5865 34.7367 32.5795C34.7086 31.889 34.2873 31.7019 33.5006 31.4574Z" fill="#006878"/>
              <path d="M114.099 28.0479C114.099 30.8244 113.846 31.184 112.99 31.5437C112.175 31.8602 111.838 32.0472 111.838 32.5938C111.838 33.644 112.737 33.644 113.467 33.644H118.482C119.086 33.644 119.592 33.2844 119.592 32.5938C119.592 32.0041 119.156 31.817 118.299 31.5005C117.316 31.1409 117.316 30.4504 117.316 28.0479V20.5528C117.316 18.7834 117.4 17.5174 118.693 16.237C119.886 15.1005 121.6 14.3237 123.412 14.3237C126.628 14.3237 128.384 16.2802 128.384 19.7328V28.0479C128.384 30.364 128.258 31.0833 127.527 31.4574L127.092 31.6876C126.699 31.9177 126.404 32.0904 126.404 32.5938C126.404 33.644 127.261 33.644 127.991 33.644H132.668C133.23 33.644 133.778 33.2844 133.778 32.5938C133.778 32.0041 133.384 31.817 132.359 31.4142C131.755 31.184 131.587 30.5511 131.587 28.0479V20.596C131.587 18.2367 131.376 16.1076 129.831 14.2806C128.413 12.6406 126.32 11.835 124.297 11.835C120.996 11.835 118.805 13.101 116.796 15.7911C116.501 13.2016 116.066 11.835 114.998 11.835C114.31 11.835 113.326 12.7844 112.807 13.2016C112.371 13.5612 111.739 14.0216 111.739 14.6977C111.739 15.2013 112.343 15.3739 113.2 15.6904C113.973 15.9637 114.099 16.7406 114.099 18.8696V28.0479Z" fill="#006878"/>
            </g>
            <defs>
              <clipPath id="clip0_1532_1567">
                <rect width="133.778" height="35" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      <div class="nav_sec">
        <ul>
          @if(homepageService.PROPERTY_DETAILS()?.showroomDetails?.ourFavoriteProductList?.productCount!) {
            <li><a class="nav_item" routerLink="/{{footerService.PropertyId()}}/our-favorites" (click)="navClickTracker('our_favorites', 'global_nav')">Our Favorites</a></li>
          }
          @if(homepageService.PROPERTY_DETAILS()?.showroomDetails?.localItemsProductList?.productCount) {
            <li><a class="nav_item" routerLink="/{{footerService.PropertyId()}}/local-items" (click)="navClickTracker('local_items', 'global_nav')">Local Items</a></li>
          }
          <li><a class="nav_item" routerLink="/{{footerService.PropertyId()}}/all-products" (click)="navClickTracker('all_products', 'global_nav')">All Products</a></li>
          <li class="dropdown" #spacesDropdown="ngbDropdown" ngbDropdown [autoClose]="true">
            <button class="dropdown-toggle" [disabled]="homepageService.DISABLE_SPACES_BUTTON_HEADER()" type="button" ngbDropdownAnchor (click)="spacesDropdown.toggle()">Spaces
              <span class="angel_icon">
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.21484 1.00028L5.21484 4.79297L9.21484 1.00028" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </button>
            <div class="dropdown-menu custom_nav_dropdown" [ngClass]="{'space_for_no_img': !homepageService.SPACE_IMAGE_AVAILABLE()}" (click)="spacesDropdown.close()" ngbDropdownMenu>
              <div class="mx-1660">
                <div class="space_container">
                  @if(homepageService.SPACE_IMAGE_AVAILABLE()) {
                    <div class="row">
                      @for (space of homepageService.SPACE_LIST(); track space.id) {
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3" data-aos="fade"  data-aos-duration="800" [attr.data-aos-delay]="$index * 100">
                          <a ngbDropdownItem class="space_card" routerLink="/{{footerService.PropertyId()}}/{{space.subspaceName}}" (click)="navClickTracker('specific_space', 'global_nav')">
                            @defer (when spacesDropdown.isOpen(); prefetch on idle) {
                              <figure>
                                <img fetchpriority="high" class="space_banner" appPlaceholderImgDirective [type]="'space'" src="{{space.image | imageSize: '_thumb'}}"  alt="{{space.title}}"/>
                                <div class="item_count_wrapper">
                                      <span class="tag_icons">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.57747 13.5001C5.11284 13.5001 4.67578 13.319 4.347 12.9905L0.509625 9.15313C0.180844 8.82435 0 8.38729 0 7.92266C0 7.45804 0.181125 7.02098 0.509625 6.69219L6.69206 0.509757C7.09425 0.10757 7.66687 -0.0729932 8.22937 0.0271318L11.484 0.609882C12.1973 0.737569 12.7623 1.3026 12.89 2.01585L13.4727 5.27048C13.5728 5.83073 13.3926 6.40532 12.9901 6.80779L6.80794 12.9899C6.47916 13.3187 6.04209 13.4996 5.57747 13.4996V13.5001ZM7.92281 1.12513C7.76109 1.12513 7.60388 1.18898 7.48744 1.30541L1.30528 7.48757C1.06537 7.72748 1.06537 8.11785 1.30528 8.35776L5.14266 12.1951C5.38284 12.4353 5.77266 12.4353 6.01284 12.1951L12.195 6.01298C12.3373 5.87066 12.4009 5.66732 12.3654 5.46932L11.7827 2.21469C11.7377 1.96269 11.538 1.76301 11.2857 1.71773L8.03109 1.13498C7.99509 1.12851 7.95881 1.12541 7.92281 1.12541V1.12513Z" fill="#FCFCFC"/>
                                      <path d="M9.84375 4.6875C10.3097 4.6875 10.6875 4.30974 10.6875 3.84375C10.6875 3.37776 10.3097 3 9.84375 3C9.37776 3 9 3.37776 9 3.84375C9 4.30974 9.37776 4.6875 9.84375 4.6875Z" fill="#FCFCFC"/>
                                    </svg>
                                  </span>
                                  <span class="inner_counts">{{ space.productCount }}</span>
                                </div>
                              </figure>
                              <div class="space_content">
                                <p class="name_of_space">{{ space.title }}</p>
                              </div>
                            } @placeholder (minimum 2000ms) {
                              <div class="header_skelton">
                                <p-skeleton height="100px" width="100px"></p-skeleton>
                              </div>
                            } @loading (minimum 200ms) {
                              <div class="header_skelton">
                                <p-skeleton height="100px" width="100px"></p-skeleton>
                              </div>
                            }
                          </a>
                        </div>
                      }
                    </div>
                  }
                  @else {
                    <!-- space for no image starts here  -->
                    <div class="row space_without_image">
                      @for(chunkSpaces of displayedSpaces(); track chunkSpaces) {
                        <div class="col-md-6">
                          <div class="text_space_menu_outer">
                            @for (space of chunkSpaces; track space.id;) {
                              <div class="text_space_wrap">
                                <a class="text_space_inner" routerLink="/{{footerService.PropertyId()}}/{{space.subspaceName}}" (click)="navClickTracker('specific_space', 'global_nav')">{{ space.title }}
                                  <span class="space_tag"><i class="icons"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.37084 13C4.92343 13 4.50256 12.8256 4.18596 12.5093L0.490745 8.81404C0.174144 8.49744 0 8.07657 0 7.62916C0 7.18174 0.174415 6.76087 0.490745 6.44427L6.44415 0.490872C6.83143 0.103584 7.38284 -0.070289 7.92451 0.0261267L11.0586 0.587288C11.7454 0.710245 12.2895 1.25434 12.4124 1.94117L12.9736 5.07522C13.07 5.61472 12.8964 6.16803 12.5089 6.55558L6.55573 12.5087C6.23913 12.8253 5.81826 12.9995 5.37084 12.9995V13ZM7.6293 1.08345C7.47357 1.08345 7.32218 1.14493 7.21006 1.25705L1.25693 7.21018C1.02591 7.4412 1.02591 7.81711 1.25693 8.04813L4.95214 11.7433C5.18343 11.9746 5.5588 11.9746 5.79009 11.7433L11.7432 5.79022C11.8803 5.65318 11.9415 5.45737 11.9073 5.2667L11.3462 2.13265C11.3028 1.88998 11.1106 1.69769 10.8676 1.65409L7.73357 1.09293C7.6989 1.0867 7.66397 1.08372 7.6293 1.08372V1.08345Z" fill="#666666"/>
                                        <path d="M9.47948 4.51317C9.92821 4.51317 10.292 4.1494 10.292 3.70068C10.292 3.25195 9.92821 2.88818 9.47948 2.88818C9.03076 2.88818 8.66699 3.25195 8.66699 3.70068C8.66699 4.1494 9.03076 4.51317 9.47948 4.51317Z" fill="#666666"/>
                                      </svg>
                                    </i>{{ space.productCount }}
                                  </span>
                                </a>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    </div>
                      <!-- space for no image ends here  -->
                  }
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile_header">
      <div class="mobile_back_btn">
        <a class="back-btn" routerLink="/{{footerService.PropertyId()}}" [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="d-none" (click)="navClickTracker('back', 'global_nav')">
              <span class="el-icon">
               <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2708 12.5H6.25M6.25 12.5L12.5 6.25M6.25 12.5L12.5 18.75" stroke="black" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
        </a>
      </div>
      <div class="logo_wrapper">
        <a routerLink="/{{footerService.PropertyId()}}" class="main_logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="114" height="32" viewBox="0 0 114 32" fill="none">
            <g clip-path="url(#clip0_1280_13905)">
              <path d="M33.7789 8.0839C35.0237 8.0839 35.8975 7.20109 35.8975 5.78091C35.8975 4.56544 35.0237 3.68262 33.9225 3.68262C32.7854 3.68262 31.8757 4.56544 31.8757 5.85767C31.8877 7.07314 32.6537 8.0839 33.7789 8.0839ZM33.0487 26.2136C33.0487 27.8641 33.0128 28.4783 31.7679 28.8365C31.361 28.9644 31.0737 29.1564 31.0737 29.6809C31.0737 30.487 31.732 30.5637 32.3186 30.5637H36.4481C37.1065 30.5637 37.657 30.487 37.657 29.6809C37.657 29.118 37.286 28.9516 36.7114 28.7086C35.8377 28.3503 35.8018 27.9025 35.8018 25.5995V16.234C35.8018 11.1802 35.2511 11.1802 34.7005 11.1802C34.2576 11.1802 33.4198 11.6663 33.0248 11.8711C30.8702 12.9586 30.7266 13.2401 30.7266 13.7262C30.7266 14.2125 31.1695 14.366 31.7081 14.4939C33.0607 14.7754 33.0607 15.9525 33.0607 17.6414V26.2136H33.0487Z" fill="#000000"/>
              <path d="M89.4502 23.1047C89.4502 26.4569 87.5467 28.9262 83.9319 28.9262C81.777 28.9262 80.353 27.2758 80.353 25.2158C80.353 21.0192 84.7338 20.2643 87.6542 20.2643H89.4378V23.1047H89.4502ZM93.3998 28.8367C92.4904 28.5552 92.1907 28.069 92.1907 25.5997V19.5223V19.0873C92.1907 13.9951 89.1629 11.1675 84.1951 11.1675C82.8427 11.1675 81.2747 11.4873 79.8859 12.2166C79.3354 12.5365 78.426 13.189 78.426 13.9567C78.426 14.6476 78.9048 14.9675 79.4429 14.9675C80.8318 14.9675 80.9751 13.1123 84.3391 13.1123C86.9363 13.1123 89.2345 14.9675 89.4502 18.0381V18.422H87.1161C79.8859 18.422 77.4443 22.401 77.4443 25.3949C77.4443 29.2717 80.4728 31.1652 83.6564 31.1652C86.2901 31.1652 88.3727 29.7962 89.7609 27.3653V28.376C89.7609 29.8346 89.7609 30.5511 91.1139 30.5511H92.7177C93.304 30.5511 94.1782 30.5895 94.1782 29.6683C94.1659 29.1181 93.7952 28.9646 93.3998 28.8367Z" fill="#000000"/>
              <path d="M67.1385 29.354C63.0804 29.354 61.0815 25.6436 61.0815 21.5238C61.0815 17.3655 62.9847 13.3225 67.2219 13.3225C71.2796 13.3225 73.4346 16.9945 73.4346 21.1527C73.4222 26.4368 70.2387 29.354 67.1385 29.354ZM67.2818 11.3394C62.3503 11.3394 58.1489 15.9069 58.1489 21.3574C58.1489 26.8462 62.1348 31.3371 67.0903 31.3371C72.2731 31.3371 76.3308 26.9742 76.3308 21.2039C76.3432 15.7023 72.2131 11.3394 67.2818 11.3394Z" fill="#000000"/>
              <path d="M41.0801 25.6127C41.0801 28.082 40.8646 28.4019 40.1345 28.7218C39.4402 29.0032 39.1529 29.1696 39.1529 29.6557C39.1529 30.5897 39.919 30.5897 40.5414 30.5897H44.8146C45.3293 30.5897 45.7602 30.2699 45.7602 29.6557C45.7602 29.1312 45.3891 28.9648 44.659 28.6834C43.8211 28.3635 43.8211 27.7494 43.8211 25.6127V18.9468C43.8211 17.3731 43.8929 16.2472 44.9942 15.1084C46.0116 14.0977 47.4719 13.4068 49.016 13.4068C51.7571 13.4068 53.2533 15.1468 53.2533 18.2175V25.6127C53.2533 27.6726 53.1456 28.3123 52.5231 28.645L52.1521 28.8497C51.8169 29.0544 51.5655 29.208 51.5655 29.6557C51.5655 30.5897 52.2957 30.5897 52.9182 30.5897H56.8921C57.3709 30.5897 57.8377 30.2699 57.8377 29.6557C57.8377 29.1312 57.5145 28.9648 56.6288 28.6066C56.1141 28.4019 55.9704 27.839 55.9704 25.6127V18.9852C55.9704 16.8869 55.7909 14.9933 54.4742 13.3684C53.2652 11.9099 51.4818 11.1934 49.7581 11.1934C46.9452 11.1934 45.078 12.3193 43.3663 14.7119C43.1149 12.4088 42.7438 11.1934 41.8342 11.1934C41.2476 11.1934 40.4098 12.0378 39.9668 12.4088C39.5958 12.7287 39.0571 13.1381 39.0571 13.7394C39.0571 14.1873 39.5718 14.3408 40.302 14.6223C40.9603 14.8654 41.0681 15.5563 41.0681 17.4498V25.6127H41.0801Z" fill="#000000"/>
              <path d="M28.5479 28.6453L27.8058 28.415C27.3509 28.2742 27.0397 27.8265 27.0277 27.3147L26.513 4.31025C26.501 3.77288 26.8243 3.28669 27.3031 3.14595L28.0931 2.91565C28.5359 2.8005 29.0746 2.63418 29.0746 2.03283C29.0746 1.09884 28.6795 1.02207 28.0572 1.02207H23.341C22.7186 1.02207 22.4673 1.43149 21.9885 2.72374C19.798 8.78831 17.2365 15.0448 14.4954 21.3013C11.9458 15.2239 9.75534 9.17215 7.52895 3.14595C6.78684 1.13722 6.6432 1.00928 5.69761 1.00928H1.17303C0.622428 1.00928 0.179547 1.25237 0.179547 1.8921C0.179547 2.45505 0.562579 2.583 1.01743 2.68535L1.68773 2.82609C2.20243 2.92845 2.5735 3.42743 2.56153 4.01598L2.02289 27.417C2.01092 27.8904 1.74759 28.3126 1.34061 28.479L0.861821 28.6837C0.406972 28.8756 0 29.0419 0 29.6944C0 30.5772 0.65834 30.5772 1.31667 30.5772H5.69761C6.1764 30.5772 6.6791 30.3726 6.6791 29.7712C6.6791 29.1443 6.26019 28.914 5.542 28.6581L5.29064 28.5685L4.85972 28.415C4.45276 28.2742 4.17745 27.852 4.18942 27.3787L4.71609 4.48937C7.20578 11.795 9.94686 18.7296 13.1308 25.9969C13.5019 26.8668 14.4954 27.0332 14.9622 25.9969C17.7272 19.6892 20.8992 11.9997 23.3889 5.12909L23.9037 27.5066C23.9156 27.9544 23.6643 28.3638 23.2931 28.5301L22.8263 28.7221C22.3116 28.9396 22.0722 29.1187 22.0722 29.6433C22.0722 30.4877 22.7665 30.5772 23.353 30.5772H28.2846C28.907 30.5772 29.6012 30.5388 29.6012 29.6433C29.5773 29.0291 29.2182 28.8628 28.5479 28.6453Z" fill="#000000"/>
              <path d="M97.2304 25.6127C97.2304 28.082 97.0148 28.4019 96.2852 28.7218C95.5908 29.0032 95.3035 29.1696 95.3035 29.6557C95.3035 30.5897 96.0696 30.5897 96.6917 30.5897H100.965C101.48 30.5897 101.911 30.2699 101.911 29.6557C101.911 29.1312 101.54 28.9648 100.809 28.6834C99.9717 28.3635 99.9717 27.7494 99.9717 25.6127V18.9468C99.9717 17.3731 100.043 16.2472 101.145 15.1084C102.162 14.0977 103.622 13.4068 105.166 13.4068C107.907 13.4068 109.404 15.1468 109.404 18.2175V25.6127C109.404 27.6726 109.296 28.3123 108.673 28.645L108.303 28.8497C107.967 29.0544 107.716 29.208 107.716 29.6557C107.716 30.5897 108.446 30.5897 109.069 30.5897H113.054C113.533 30.5897 114 30.2699 114 29.6557C114 29.1312 113.665 28.9648 112.791 28.6066C112.276 28.4019 112.133 27.839 112.133 25.6127V18.9852C112.133 16.8869 111.953 14.9933 110.637 13.3684C109.428 11.9099 107.644 11.1934 105.92 11.1934C103.108 11.1934 101.241 12.3193 99.5287 14.7119C99.2772 12.4088 98.9066 11.1934 97.9965 11.1934C97.4102 11.1934 96.5718 12.0378 96.1295 12.4088C95.7582 12.7287 95.2195 13.1381 95.2195 13.7394C95.2195 14.1873 95.7341 14.3408 96.4644 14.6223C97.123 14.8654 97.2304 15.5563 97.2304 17.4498V25.6127Z" fill="#000000"/>
            </g>
            <defs>
              <clipPath id="clip0_1280_13905">
                <rect width="114" height="31.128" fill="#000000" transform="translate(0 0.666992)"/>
              </clipPath>
            </defs>
          </svg>
        </a>
        <button class="button_secondary md" routerLink="/join-us">Join us</button>
      </div>
      <div class="hamburger-menu">
        <button class="menu-btn" (click)="closeBtnClick(false)" [ngClass]="{active: showMobileMenu}">
<!--          <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8397 0.794495C20.8397 0.355714 20.5065 0 20.0954 0H10.4199C10.0088 0 9.6756 0.355714 9.6756 0.794495C9.6756 1.23328 10.0088 1.58899 10.4199 1.58899H20.0954C20.5065 1.58899 20.8397 1.23328 20.8397 0.794495ZM20.8397 7.94495C20.8397 7.50618 20.5065 7.15046 20.0954 7.15046H0.744301C0.333263 7.15046 2.67029e-05 7.50618 2.67029e-05 7.94495C2.67029e-05 8.38373 0.333263 8.73945 0.744301 8.73945H20.0954C20.5065 8.73945 20.8397 8.38373 20.8397 7.94495ZM20.0954 14.3009C20.5065 14.3009 20.8397 14.6566 20.8397 15.0954C20.8397 15.5342 20.5065 15.8899 20.0954 15.8899H0.744301C0.333263 15.8899 2.67029e-05 15.5342 2.67029e-05 15.0954C2.67029e-05 14.6566 0.333263 14.3009 0.744301 14.3009H20.0954Z" fill="black"/>-->
<!--          </svg>-->
          <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8398 0.794495C20.8398 0.355714 20.5066 0 20.0956 0H10.42C10.0089 0 9.67572 0.355714 9.67572 0.794495C9.67572 1.23328 10.0089 1.58899 10.42 1.58899H20.0956C20.5066 1.58899 20.8398 1.23328 20.8398 0.794495ZM20.8398 7.94495C20.8398 7.50618 20.5066 7.15046 20.0956 7.15046H0.744423C0.333385 7.15046 0.000148773 7.50618 0.000148773 7.94495C0.000148773 8.38373 0.333385 8.73945 0.744423 8.73945H20.0956C20.5066 8.73945 20.8398 8.38373 20.8398 7.94495ZM20.0956 14.3009C20.5066 14.3009 20.8398 14.6566 20.8398 15.0954C20.8398 15.5342 20.5066 15.8899 20.0956 15.8899H0.744423C0.333385 15.8899 0.000148773 15.5342 0.000148773 15.0954C0.000148773 14.6566 0.333385 14.3009 0.744423 14.3009H20.0956Z" fill="#222222"/>
          </svg>
        </button>
      </div>
      <div class="mobile_menu_wrapper"  [ngClass]="{active: showMobileMenu}">
        @if (showMobileMenu) {
          <div class="top_sec">
            <div class="">
              <!--            @if(openedViaSpaceHeader) {-->
              <a href="javascript:void(0);" class="link-primary-btn p-0" type="button"  routerLink="/{{footerService.PropertyId()}}" [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="d-none">
                <span class="el-icon">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path  class="inner-svg" d="M12.1875 6.5H1.25M1.25 6.5L6.5 1.25M1.25 6.5L6.5 11.75" stroke="#666666" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <p class="el-text">HOME</p>
              </a>
              <!--            }-->
            </div>
            <button class="close_btn" (click)="closeBtnClick()">
              <!--            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--              <path d="M14.15 1.84963C13.8166 1.51627 13.2766 1.51627 12.9432 1.84963L7.99963 6.79323L3.05602 1.84963C2.72267 1.51684 2.18321 1.51739 1.84985 1.85018C1.51706 2.18354 1.51649 2.723 1.84929 3.05636L6.7929 7.99996L1.84929 12.9436C1.51651 13.2769 1.51706 13.8164 1.84985 14.1497C2.18321 14.4825 2.72267 14.4831 3.05602 14.1503L7.99963 9.20669L12.9432 14.1503C13.2766 14.4831 13.8161 14.4825 14.1494 14.1497C14.4822 13.8164 14.4828 13.2769 14.15 12.9436L9.20636 7.99996L14.15 3.05636C14.31 2.89635 14.4 2.67912 14.4 2.45299C14.4 2.22686 14.31 2.00964 14.15 1.84963L14.15 1.84963Z" fill="#666666"/>-->
              <!--            </svg>-->
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6875 0.312522C15.2708 -0.104174 14.5958 -0.104174 14.1791 0.312522L7.99957 6.49202L1.82006 0.312522C1.40337 -0.103463 0.729039 -0.10277 0.312342 0.313217C-0.103643 0.729912 -0.104354 1.40424 0.311648 1.82093L6.49115 8.00044L0.311648 14.1799C-0.104337 14.5966 -0.103644 15.271 0.312342 15.6877C0.729039 16.1036 1.40337 16.1044 1.82006 15.6884L7.99957 9.50885L14.1791 15.6884C14.5958 16.1043 15.2701 16.1036 15.6868 15.6877C16.1028 15.271 16.1035 14.5966 15.6875 14.1799L9.50798 8.00044L15.6875 1.82093C15.8875 1.62092 16 1.34939 16 1.06673C16 0.784066 15.8875 0.512533 15.6875 0.312523L15.6875 0.312522Z" fill="#222222"/>
              </svg>


            </button>
          </div>
          <div class="navbar_content" id="navbar_content_section">
            <div class="mob_nav_top">
              <div class="space1"></div>
              <!--        for All Products starts here-->
              @if(homepageService.PROPERTY_DETAILS()?.showroomDetails?.allProductList?.productCount) {
                <div class="product_content_wrapper" routerLink="/{{footerService.PropertyId()}}/all-products" (click)="closeBtnClick(); navClickTracker('all_products', 'mobile_nav')">
                  <div class="product_content_inner">
                    <p class="content-text">All products</p>
                    <div class="price_tag_wrapper">
                      <div class="top_count_wrapper">
                    <span class="tag_icons">
                             <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.37084 13C4.92343 13 4.50256 12.8256 4.18596 12.5093L0.490745 8.81404C0.174144 8.49744 0 8.07657 0 7.62916C0 7.18174 0.174415 6.76087 0.490745 6.44427L6.44415 0.490872C6.83143 0.103584 7.38284 -0.070289 7.92451 0.0261267L11.0586 0.587288C11.7454 0.710245 12.2895 1.25434 12.4124 1.94117L12.9736 5.07522C13.07 5.61472 12.8964 6.16803 12.5089 6.55558L6.55573 12.5087C6.23913 12.8253 5.81826 12.9995 5.37084 12.9995V13ZM7.6293 1.08345C7.47357 1.08345 7.32218 1.14493 7.21006 1.25705L1.25693 7.21018C1.02591 7.4412 1.02591 7.81711 1.25693 8.04813L4.95214 11.7433C5.18343 11.9746 5.5588 11.9746 5.79009 11.7433L11.7432 5.79022C11.8803 5.65318 11.9415 5.45737 11.9073 5.2667L11.3462 2.13265C11.3028 1.88998 11.1106 1.69769 10.8676 1.65409L7.73357 1.09293C7.6989 1.0867 7.66397 1.08372 7.6293 1.08372V1.08345Z" fill="#666666"/>
                                <path d="M9.47948 4.51317C9.92821 4.51317 10.292 4.1494 10.292 3.70068C10.292 3.25195 9.92821 2.88818 9.47948 2.88818C9.03076 2.88818 8.66699 3.25195 8.66699 3.70068C8.66699 4.1494 9.03076 4.51317 9.47948 4.51317Z" fill="#666666"/>
                              </svg>
                    </span>
                        <span class="inner_counts"> {{ homepageService.PROPERTY_DETAILS()?.showroomDetails?.allProductList?.productCount }}</span>
                      </div>
                      <span class="arrow_icon">
                   <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3355_388)">
                    <path d="M12.3885 7.56118L4.98802 0.181178C4.74555 -0.0604777 4.35299 -0.0600715 4.11093 0.182428C3.86906 0.424895 3.86968 0.817675 4.11218 1.05952L11.0722 8.00027L4.11193 14.941C3.86946 15.1829 3.86884 15.5754 4.11068 15.8179C4.23202 15.9395 4.39099 16.0002 4.54996 16.0002C4.70852 16.0002 4.86686 15.9399 4.98799 15.8191L12.3885 8.43933C12.5053 8.32315 12.5708 8.16502 12.5708 8.00027C12.5708 7.83552 12.5051 7.67759 12.3885 7.56118Z" fill="#222222"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3355_388">
                    <rect width="16" height="16" fill="white" transform="translate(0.25)"/>
                    </clipPath>
                    </defs>
                    </svg>

                  </span>
                    </div>
                  </div>

                </div>
              }
              <!--        for All Products ends here-->


              <!--              our favorites starts here -->
              @if(homepageService.PROPERTY_DETAILS()?.showroomDetails?.ourFavoriteProductList?.productCount) {
                <div class="product_content_wrapper" routerLink="/{{footerService.PropertyId()}}/our-favorites" (click)="closeBtnClick(); navClickTracker('our_favorites', 'mobile_nav');">
                  <div class="product_content_inner">
                    <p class="content-text">Our Favorites</p>
                    <div class="price_tag_wrapper">
                      <div class="top_count_wrapper">
                    <span class="tag_icons">
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.37084 13C4.92343 13 4.50256 12.8256 4.18596 12.5093L0.490745 8.81404C0.174144 8.49744 0 8.07657 0 7.62916C0 7.18174 0.174415 6.76087 0.490745 6.44427L6.44415 0.490872C6.83143 0.103584 7.38284 -0.070289 7.92451 0.0261267L11.0586 0.587288C11.7454 0.710245 12.2895 1.25434 12.4124 1.94117L12.9736 5.07522C13.07 5.61472 12.8964 6.16803 12.5089 6.55558L6.55573 12.5087C6.23913 12.8253 5.81826 12.9995 5.37084 12.9995V13ZM7.6293 1.08345C7.47357 1.08345 7.32218 1.14493 7.21006 1.25705L1.25693 7.21018C1.02591 7.4412 1.02591 7.81711 1.25693 8.04813L4.95214 11.7433C5.18343 11.9746 5.5588 11.9746 5.79009 11.7433L11.7432 5.79022C11.8803 5.65318 11.9415 5.45737 11.9073 5.2667L11.3462 2.13265C11.3028 1.88998 11.1106 1.69769 10.8676 1.65409L7.73357 1.09293C7.6989 1.0867 7.66397 1.08372 7.6293 1.08372V1.08345Z" fill="#666666"/>
                                <path d="M9.47948 4.51317C9.92821 4.51317 10.292 4.1494 10.292 3.70068C10.292 3.25195 9.92821 2.88818 9.47948 2.88818C9.03076 2.88818 8.66699 3.25195 8.66699 3.70068C8.66699 4.1494 9.03076 4.51317 9.47948 4.51317Z" fill="#666666"/>
                              </svg>
                    </span>
                        <span class="inner_counts"> {{ homepageService.PROPERTY_DETAILS()?.showroomDetails?.ourFavoriteProductList?.productCount }}</span>
                      </div>
                      <span class="arrow_icon">
                   <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3355_388)">
                    <path d="M12.3885 7.56118L4.98802 0.181178C4.74555 -0.0604777 4.35299 -0.0600715 4.11093 0.182428C3.86906 0.424895 3.86968 0.817675 4.11218 1.05952L11.0722 8.00027L4.11193 14.941C3.86946 15.1829 3.86884 15.5754 4.11068 15.8179C4.23202 15.9395 4.39099 16.0002 4.54996 16.0002C4.70852 16.0002 4.86686 15.9399 4.98799 15.8191L12.3885 8.43933C12.5053 8.32315 12.5708 8.16502 12.5708 8.00027C12.5708 7.83552 12.5051 7.67759 12.3885 7.56118Z" fill="#222222"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3355_388">
                    <rect width="16" height="16" fill="white" transform="translate(0.25)"/>
                    </clipPath>
                    </defs>
                    </svg>

                  </span>
                    </div>
                  </div>

                </div>
              }
              <!--              our favorites ends here -->


              <!--        for Local Items starts here-->
              @if(homepageService.PROPERTY_DETAILS()?.showroomDetails?.localItemsProductList?.productCount) {
                <div class="product_content_wrapper" routerLink="/{{footerService.PropertyId()}}/local-items" (click)="closeBtnClick(); navClickTracker('local_items', 'mobile_nav')" >
                  <div class="product_content_inner" >
                    <p class="content-text">Local Items</p>
                    <div class="price_tag_wrapper">
                      <div class="top_count_wrapper">
                    <span class="tag_icons">
                             <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.37084 13C4.92343 13 4.50256 12.8256 4.18596 12.5093L0.490745 8.81404C0.174144 8.49744 0 8.07657 0 7.62916C0 7.18174 0.174415 6.76087 0.490745 6.44427L6.44415 0.490872C6.83143 0.103584 7.38284 -0.070289 7.92451 0.0261267L11.0586 0.587288C11.7454 0.710245 12.2895 1.25434 12.4124 1.94117L12.9736 5.07522C13.07 5.61472 12.8964 6.16803 12.5089 6.55558L6.55573 12.5087C6.23913 12.8253 5.81826 12.9995 5.37084 12.9995V13ZM7.6293 1.08345C7.47357 1.08345 7.32218 1.14493 7.21006 1.25705L1.25693 7.21018C1.02591 7.4412 1.02591 7.81711 1.25693 8.04813L4.95214 11.7433C5.18343 11.9746 5.5588 11.9746 5.79009 11.7433L11.7432 5.79022C11.8803 5.65318 11.9415 5.45737 11.9073 5.2667L11.3462 2.13265C11.3028 1.88998 11.1106 1.69769 10.8676 1.65409L7.73357 1.09293C7.6989 1.0867 7.66397 1.08372 7.6293 1.08372V1.08345Z" fill="#666666"/>
                                <path d="M9.47948 4.51317C9.92821 4.51317 10.292 4.1494 10.292 3.70068C10.292 3.25195 9.92821 2.88818 9.47948 2.88818C9.03076 2.88818 8.66699 3.25195 8.66699 3.70068C8.66699 4.1494 9.03076 4.51317 9.47948 4.51317Z" fill="#666666"/>
                              </svg>
                    </span>
                        <span class="inner_counts">  {{ homepageService.PROPERTY_DETAILS()?.showroomDetails?.localItemsProductList?.productCount }}</span>
                      </div>
                      <span class="arrow_icon">
                   <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3355_388)">
                    <path d="M12.3885 7.56118L4.98802 0.181178C4.74555 -0.0604777 4.35299 -0.0600715 4.11093 0.182428C3.86906 0.424895 3.86968 0.817675 4.11218 1.05952L11.0722 8.00027L4.11193 14.941C3.86946 15.1829 3.86884 15.5754 4.11068 15.8179C4.23202 15.9395 4.39099 16.0002 4.54996 16.0002C4.70852 16.0002 4.86686 15.9399 4.98799 15.8191L12.3885 8.43933C12.5053 8.32315 12.5708 8.16502 12.5708 8.00027C12.5708 7.83552 12.5051 7.67759 12.3885 7.56118Z" fill="#222222"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3355_388">
                    <rect width="16" height="16" fill="white" transform="translate(0.25)"/>
                    </clipPath>
                    </defs>
                    </svg>

                  </span>
                    </div>
                  </div>

                </div>
              }

              <!--        for Local Items ends here-->

            </div>
            <p class="browse_space_heading" [ngClass]="{'no_img_space':!homepageService.SPACE_IMAGE_AVAILABLE()}">Browse By Space</p>
            <div class="space_container update_space">
              @if (homepageService.SPACE_IMAGE_AVAILABLE()) {
                <div class="row">
                  @for (space of homepageService.SPACE_LIST(); track space.id) {
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                      <div class="space_card" routerLink="/{{footerService.PropertyId()}}/{{space.subspaceName}}" (click)="closeBtnClick(); navClickTracker('specific_space', 'mobile_nav');">
                        @defer (when showMobileMenu; prefetch on idle) {
                          <figure>
                            <img class="space_banner" appPlaceholderImgDirective [type]="'space'" src="{{space.image | imageSize: '_thumb'}}"  alt="{{space.title}}"/>
                            <div class="item_count_wrapper">
                                  <span class="tag_icons">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.57747 13.5001C5.11284 13.5001 4.67578 13.319 4.347 12.9905L0.509625 9.15313C0.180844 8.82435 0 8.38729 0 7.92266C0 7.45804 0.181125 7.02098 0.509625 6.69219L6.69206 0.509757C7.09425 0.10757 7.66687 -0.0729932 8.22937 0.0271318L11.484 0.609882C12.1973 0.737569 12.7623 1.3026 12.89 2.01585L13.4727 5.27048C13.5728 5.83073 13.3926 6.40532 12.9901 6.80779L6.80794 12.9899C6.47916 13.3187 6.04209 13.4996 5.57747 13.4996V13.5001ZM7.92281 1.12513C7.76109 1.12513 7.60388 1.18898 7.48744 1.30541L1.30528 7.48757C1.06537 7.72748 1.06537 8.11785 1.30528 8.35776L5.14266 12.1951C5.38284 12.4353 5.77266 12.4353 6.01284 12.1951L12.195 6.01298C12.3373 5.87066 12.4009 5.66732 12.3654 5.46932L11.7827 2.21469C11.7377 1.96269 11.538 1.76301 11.2857 1.71773L8.03109 1.13498C7.99509 1.12851 7.95881 1.12541 7.92281 1.12541V1.12513Z" fill="#FCFCFC"/>
                                      <path d="M9.84375 4.6875C10.3097 4.6875 10.6875 4.30974 10.6875 3.84375C10.6875 3.37776 10.3097 3 9.84375 3C9.37776 3 9 3.37776 9 3.84375C9 4.30974 9.37776 4.6875 9.84375 4.6875Z" fill="#FCFCFC"/>
                                    </svg>
                                  </span>
                              <span class="inner_counts"> {{ space.productCount }}</span>
                            </div>
                          </figure>
                        } @placeholder (minimum 2000ms) {
                          <div class="header_skelton">
                            <p-skeleton height="100px" width="180px"></p-skeleton>
                          </div>
                        } @loading (minimum 200ms) {
                          <div class="header_skelton">
                            <p-skeleton height="100px" width="180px"></p-skeleton>
                          </div>
                        }
                        <div class="space_content">
                          <p class="name_of_space">{{ space.title }}</p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
            } @else {
              <!--  space for no image starts here for mobile -->
              <div class="row space_without_image">
                <div class="col-12">
                  <div class="space1"></div>
                  @for (space of homepageService.SPACE_LIST(); track space.id) {
                    <div class="text_space_menu_outer">
                      <a class="text_space_wrap" routerLink="/{{footerService.PropertyId()}}/{{space.subspaceName}}" (click)="closeBtnClick(); navClickTracker('specific_space', 'mobile_nav');">
                        <p class="text_space_inner">{{ space.title }}</p>
                        <div class="tag_and_arrow">
                          <span class="space_tag"><i class="icons"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.37084 13C4.92343 13 4.50256 12.8256 4.18596 12.5093L0.490745 8.81404C0.174144 8.49744 0 8.07657 0 7.62916C0 7.18174 0.174415 6.76087 0.490745 6.44427L6.44415 0.490872C6.83143 0.103584 7.38284 -0.070289 7.92451 0.0261267L11.0586 0.587288C11.7454 0.710245 12.2895 1.25434 12.4124 1.94117L12.9736 5.07522C13.07 5.61472 12.8964 6.16803 12.5089 6.55558L6.55573 12.5087C6.23913 12.8253 5.81826 12.9995 5.37084 12.9995V13ZM7.6293 1.08345C7.47357 1.08345 7.32218 1.14493 7.21006 1.25705L1.25693 7.21018C1.02591 7.4412 1.02591 7.81711 1.25693 8.04813L4.95214 11.7433C5.18343 11.9746 5.5588 11.9746 5.79009 11.7433L11.7432 5.79022C11.8803 5.65318 11.9415 5.45737 11.9073 5.2667L11.3462 2.13265C11.3028 1.88998 11.1106 1.69769 10.8676 1.65409L7.73357 1.09293C7.6989 1.0867 7.66397 1.08372 7.6293 1.08372V1.08345Z" fill="#666666"/>
                                <path d="M9.47948 4.51317C9.92821 4.51317 10.292 4.1494 10.292 3.70068C10.292 3.25195 9.92821 2.88818 9.47948 2.88818C9.03076 2.88818 8.66699 3.25195 8.66699 3.70068C8.66699 4.1494 9.03076 4.51317 9.47948 4.51317Z" fill="#666666"/>
                              </svg>
                            </i>{{ space.productCount }}
                          </span>
                          <span class="arrow_icon">
                           <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_3355_388)">
                              <path d="M12.3885 7.56118L4.98802 0.181178C4.74555 -0.0604777 4.35299 -0.0600715 4.11093 0.182428C3.86906 0.424895 3.86968 0.817675 4.11218 1.05952L11.0722 8.00027L4.11193 14.941C3.86946 15.1829 3.86884 15.5754 4.11068 15.8179C4.23202 15.9395 4.39099 16.0002 4.54996 16.0002C4.70852 16.0002 4.86686 15.9399 4.98799 15.8191L12.3885 8.43933C12.5053 8.32315 12.5708 8.16502 12.5708 8.00027C12.5708 7.83552 12.5051 7.67759 12.3885 7.56118Z" fill="#222222"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_3355_388">
                              <rect width="16" height="16" fill="white" transform="translate(0.25)"/>
                              </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                  }
                </div>
              </div>
              <!--            space for no image ends here for mobile -->
            }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</header>
